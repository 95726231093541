<template>
  <v-app :style="'background-color:' + $Settings.color_1">
    <Loading v-if="!isReady" />
    <v-main v-else>
      <Header v-if="$route.name != 'login'" :key="$route.fullPath" />
      <v-layout fill-height justify-center align-start class="px-5">
        <transition>
          <router-view :key="$route.fullPath" />
        </transition>
      </v-layout>
      <v-footer dark app width="100%" color="transparent" class="justify-center">
        <v-chip small color="white" class="black--text" dark>
          Powered by physioup | Physiotherapie Berlin
        </v-chip>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import Header from './components/Header.vue';
import Loading from './components/Loading.vue';

export default {
  components: { Header, Loading },
  name: 'App',

  data: () => ({
    isReady: false,
  }),
  watch: {
    $route () {
      this.isReady = false;
      setTimeout(() => {
        this.isReady = true;
      }, 1000);
    },
  },
  methods: {

  },
  mounted () {

  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

html {
  overflow-y: hidden;
}

* {
  font-family: 'Roboto' !important;
}

body {
  padding: 0 !important;
}

.Block {
  display: block;
  text-align: center;
}

/* Change Scrollbar Style and Color for Chrome and Firefox */
::-webkit-scrollbar {
  width: 2px;
  margin-left: 5px;
  border-radius: 15px;
  background-color: #49A400;
}
</style>