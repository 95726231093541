<template>
    <v-btn :loading="Loading" class="ml-2" color="red" dark @click="InsertData">
        {{ $General.GetString('Generate Dummy-Data') }}
    </v-btn>
</template>

<script>
export default {
    data: () => ({
        Loading: false,
    }),
    methods: {
        async InsertData () {
            // Import Dummy Data from Assets Folder
            var finalCount = 200;
            this.Loading = true;
            for (let i = 0; i < finalCount; i++) {
                const Item = {
                    // Generate Number from 5466 to 5470
                    "owner": Math.floor(Math.random() * (5470 - 5466 + 1)) + 5466,
                    // Review 1 a random number from 1 to 5
                    "review1": Math.floor(Math.random() * (5 - 1 + 1)) + 1,
                    "review2": Math.floor(Math.random() * (5 - 1 + 1)) + 1,
                    "review3": Math.floor(Math.random() * (5 - 1 + 1)) + 1,
                    "review4": Math.floor(Math.random() * (5 - 1 + 1)) + 1,
                    // Generate a random string with 10 characters
                    "notes": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                    "date": new Date(new Date().getTime() + (i * 10000)),
                };
                this.$http({
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: this.$General.APIReviews() + '/post',
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem(this.$General.LSSettings())).User.Token,
                    },
                    data: Item,
                }).then(() => {
                    if (i == (finalCount - 1)) {
                        this.Loading = false;
                        this.$General.Alert('Done', '', 'success');
                    }
                }).catch((error) => {
                    this.Loading = false;
                    console.log(error);
                });
            }

        },
    },
}
</script>

<style></style>