<template>
    <v-card flat color="transparent" class="text-center">
        <v-progress-circular indeterminate :color="dark ? $Settings.color_2 : $Settings.color_4" width="10" size="100"
            class="mt-10 mx-auto" />
    </v-card>
</template>

<script>
export default {
    props: {
        dark: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style></style>