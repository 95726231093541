import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { auth: [] },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: { auth: ['administrator', 'contributor'] },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/review',
    name: 'review',
    meta: { auth: ['administrator', 'contributor'] },
    component: () => import('../views/Review.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: { auth: ['administrator'] },
    component: () => import('../views/Statistics.vue')
  },
  {
    path: '*',
    redirect: '/home'
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

Vue.prototype.$User = {};
router.beforeEach((to, from, next) => {
  if (to.name == 'login') {
    next()
  }
  else {
    if (to.matched.some(record => record.meta.auth)) {
      Vue.prototype.$http({
        method: 'GET',
        url: Vue.prototype.$General.APIUser(),
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem(Vue.prototype.$General.LSSettings())).User.Token,
        },
        data: {}
      }).then((response) => {
        Vue.prototype.$User = response.data;
        next()
      }).catch(() => {
        next({ name: 'login' });
      });
    }
    else { next({ name: 'login' }) }
  }
})

export default router
