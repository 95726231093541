<template>
  <v-container fluid>
    <v-system-bar fixed app :color="$Settings.color_3" dark height="50">
      <v-btn @click="[Menu.Open = !Menu.Open]" class="rounded-0" :color="$Settings.color_2">
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
      <InsertDummyData v-if="false" />
      <v-spacer />
      <v-fade-transition>
        <v-chip v-if="Menu.Time != ''" class="rounded-0" :color="$Settings.color_2">{{ Menu.Time }}</v-chip>
      </v-fade-transition>
    </v-system-bar>
    <v-navigation-drawer v-model="Menu.Open" app width="300" temporary>
      <v-img :src="$Settings.logo" contain height="70" class="my-5" />
      <v-divider />
      <v-list>
        <v-list-item-group>
          <v-list-item v-for="(item, i) in Menu.Items" :key="i" :to="item.to">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="px-2 mb-3">
          <v-btn @click="Logout" color="error" block>
            <v-icon>mdi-logout</v-icon>
            {{ $General.GetString('Logout') }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import InsertDummyData from './InsertDummyData.vue';
export default {
  components: {
    InsertDummyData
  },
  data: () => ({
    Menu: {
      Open: false,
      Time: ''
    }
  }),
  watch: {

  },
  methods: {
    async Logout () {
      await this.$General.UpdateToken('');
      await this.$router.push({ name: 'login' });
    },
    UpdateTime () {
      setInterval(() => {
        this.Menu.Time = this.$Moment().format('dddd DD.MM.YYYY - HH:mm:ss');
      }, 1000);
    },
    LoadMenu () {
      this.Menu.Items = [
        { title: this.$General.GetString('Home'), icon: 'mdi-home', to: '/home' },
        { title: this.$General.GetString('Review'), icon: 'mdi-account', to: '/review' },
      ];
      try {
        if (this.$User.role[0] == 'administrator') {
          this.Menu.Items.push({ title: this.$General.GetString('Statistics'), icon: 'mdi-email', to: '/statistics' });
        }
      }
      catch (e) {
        //
      }
    }
  },
  async mounted () {
    this.UpdateTime();
    this.LoadMenu();
  },
}
</script>
<style></style>
