import Dictionary from "../subclasses/dictionary.json";
// https://github.com/avil13/vue-sweetalert2
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2';

export default {
    // Keys Values
    LSSettings: function () {
        return "N9O0MLY2AP";
    },
    MainDomain: function () {
        return "https://www.physioup.de/";
    },
    MainDomainAPI: function () {
        return this.MainDomain() + "wp-json";
    },
    // APIs
    APILogin: function () {
        return this.MainDomainAPI() + "/api-bearer-auth/v1/login";
    },
    APIUser: function () {
        return this.MainDomainAPI() + "/user/v1/get";
    },
    APIReviews: function () {
        return this.MainDomainAPI() + "/reviews/v1";
    },
    APITeam: function () {
        return this.MainDomainAPI() + "/teams/v1/get";
    },
    APISettings: function () {
        return this.MainDomainAPI() + "/wp/v2/posts/5496";
    },
    APISubscribers: function () {
        return this.MainDomainAPI() + "/subscribers/v1";
    },
    APISendMail: function () {
        return this.MainDomainAPI() + "/emails/v1";
    },

    // Methods  
    UpdateToken: function (Token) {
        var LSSettings = JSON.parse(localStorage.getItem(this.LSSettings()));
        LSSettings.User.Token = Token;
        localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
    },
    UpdateLanguage: function (Language) {
        var LSSettings = JSON.parse(localStorage.getItem(this.LSSettings()));
        LSSettings.User.Language = Language;
        localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
    },
    IsEmpty: function (Value) {
        if (Value == undefined || Value == null || Value == '')
            return true;
        else
            return false;
    },
    GetString: function (textString, wantedLanguage) {
        if (wantedLanguage == null) {
            var Language = JSON.parse(localStorage.getItem(this.LSSettings())).User.Language;
            try {
                return Dictionary.Strings.find(Word => Word.Key === textString)[Language.toUpperCase()]
            }
            catch
            {
                // console.log("404 '" + Language.toUpperCase() + "' : " + textString);
                return textString;
            }
        }
        else {
            try {
                return Dictionary.Strings.find(Word => Word.Key === textString)[(wantedLanguage).toUpperCase()]
            }
            catch
            {
                // console.log("404 '" + wantedLanguage.toUpperCase() + "' : " + textString);
                return textString;
            }
        }
    },
    ConfirmAlert: function () {
        return Swal.fire({
            position: "center",
            icon: "warning",
            title: this.GetString("sure"),
            showCancelButton: true,
            confirmButtonColor: "#2E7D32",
            cancelButtonColor: "#C62828",
            confirmButtonText: this.GetString("submit"),
            cancelButtonText: this.GetString("cancel"),
        }).then((Result) => {
            if (Result.isConfirmed) {
                return true;
            } else {
                return false;
            }
        });
    },
    Alert: function (Title, Text = "", Icon = "error", showConfirmButton = true) {
        return Swal.fire({
            position: "center",
            title: Title,
            text: Text,
            icon: Icon,
            showConfirmButton: showConfirmButton,
            confirmButtonColor: "#3E8742",
            confirmButtonText: this.GetString("submit"),
        });
    },
    AlertWithTextField: function (Title, Text = "", Icon = "error") {
        return Swal.fire({
            position: "center",
            title: Title,
            text: Text,
            icon: Icon,
            showConfirmButton: true,
            confirmButtonColor: "#3E8742",
            confirmButtonText: this.GetString("Bewertung Abgeben"),
            input: "textarea",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: false,
        });
    },

    // eslint-disable-next-line no-unused-vars
    PrintResponse: function (Response) {
        console.log('-----------------------------------------------------');
        console.log(Response);
        console.log('-----------------------------------------------------');
    },
};