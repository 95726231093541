import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import Moment from 'moment';
import General from '../src/subclasses/general.js';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.prototype.$http = Axios;
Vue.prototype.$Moment = Moment;
Vue.prototype.$General = General;
Vue.prototype.$LSSettings = localStorage.getItem(Vue.prototype.$General.LSSettings());

Vue.config.productionTip = false

if (Vue.prototype.$LSSettings == null) {
  var LS = new Object();
  LS.User = {
    Token: null,
    Language: 'DE',
  };
  localStorage.setItem(Vue.prototype.$General.LSSettings(), JSON.stringify(LS));
  window.location.reload();
}

Vue.prototype.$http.get(Vue.prototype.$General.APISettings()).then((response) => {
  Vue.prototype.$Settings = response.data.acf;
  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});
